import resful from "../../services/resful.js";
export const dashboardSV = {
  getAll: async function(data, next) {
    let res = await resful.get("/app/page?" + data);
    next(res);
  },
  getDataPage: async function(data, next) {
    let res = await resful.get("/app/page", data);
    next(res);
  },
  createNew: async function(data, next) {
    let res = await resful.post("/app/creat_page", data);
    next(res);
  },
  createNewByTemplate: async function(data, next) {
    let res = await resful.post("/app/creat_page_by_template", data);
    next(res);
  },
  removePage: async function(data, next) {
    let res = await resful.post("/app/delete_page", data);
    next(res);
  },
  // get all category
  getListCategory: async function(data, next) {
    let res = await resful.get("/app/category?type=marketplace&type_second=app", data);
    next(res);
  },
  // get App in category
  getAppInCategory: async function(data, next) {
    let res = await resful.get("/public/templates?" + data);
    next(res);
  },
  getInfo: async function(data, next) {
    let res = await resful.get("/app/me", data);
    next(res);
  },
  // get app and category google
  getAppMarketCate: async function(data,next) {
    let res = await resful.get("/app/get_templates_by_category_market?limit=50&type=app", data);
    next(res);
  },
  search: async function(data, next) {
    let res = await resful.post("/app/search_page_and_templates", data);
    next(res);
  },
};
