import { sevice } from "./profile-account.service.js";
import CookieService from "@/services/cookie.js"

export default {
    data: function () {
        return {
            user: {},
            accountId: "",
            link: "",
            list_affiliate: [],
            copied: false
        }
    },
    methods: {
        transfer: function () {
            var thisVue = this;
            sevice.transferapp({ appid: thisVue.$route.params.id, accoundid: thisVue.accountId }, function (data) {
                if (data && data.code === 200) {
                    $("#modal-profile").modal("hide");
                    thisVue.$router.push({ name: "Dashboard" });
                }
            });
        },
        copy_clip_board: function (link) {
            this.copied = true
            var $temp = $("<input>");
            $("body").append($temp);
            $temp.val(link).focus().select();
            document.execCommand("copy");
            $temp.remove();
        },
        getAffiliate() {
            sevice.getAffiliate({}, (res) => {
                this.list_affiliate = res.data

                if (res.data && res.data.length > 0 && res.data[0].ref) {
                    let domain_name = (process.env.NODE_ENV === 'production') ? 'appon.vn' : ''
                    CookieService.set_cookie('my_ref', res.data[0].ref, 30000, domain_name)
                }
            })
        }
    },
    mounted: function () {
        this.user = JSON.parse(localStorage.getItem("user"));
        this.link = `${window.location.href}studio/login?ref=`
        this.getAffiliate()
    }
}