import AppListPage from "../../components/dashboard/list-page/list-page.vue";
import { dashboardSV } from "./themes-botup.service";
import MenuLeft from "../../components/dashboard/menu-left/menu-left.vue";
import loading from "../loading/loading.vue";
import shareApp from "../../components/dashboard/share-app/share-app.vue";
import Profile from "../../components/editor-v3/help-editor-block/profile-account/profile-account.vue";

// import jQuery from "jquery";
// window.jQuery = jQuery;
// window.$ = jQuery;
// import _ from "lodash";

// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap";

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick.min.js"

export default {
  name: "dashboard",
  components: {
    AppListPage,
    MenuLeft,
    loading,
    shareApp,
    managePlans,
    Profile,
  },
  data: function () {
    return {
      page: {},
      listPage: [],
      newName: "App name",
      newType: "app",
      user: {},
      cate_select: "",
      parent_cate_select: "",
      listCate: [],
      app_id_clone: "",
      linkAppShare: "https://app.appon.vn/",
      id_template_app: "5d8c7aa2a91a17680bd15bda",
      list_template_app: [],
      menu: {
        select: 0
      },
      skip: 0,
      is_scroll: true,
      cate_id: "",
      search: "",
    };
  },
  methods: {
    handleSearch: function () {
      this.list_template_app = [];
      this.is_scroll = false;
      dashboardSV.search(JSON.stringify({
        search: this.search
      }), data => {
        this.list_template_app = data.data.page
      })
    },
    getTemplateApp: function () {
      var thisVue = this;
      dashboardSV.getAppInCategory({ id: this.id_template_app }, function (data) {
        if (data && data.code === 200) {
          thisVue.list_template_app = data.data;
          console.log('list_template_app', data.data)
        } else {
          thisVue.list_template_app = [];
        }
      });
    },
    showProfile: function () {
      $("#modal-profile").modal("show");
    },
    showManagePlans: function () {
      $("#manager-plans").modal("show");
    },
    shareApp: function (val) {
      this.linkAppShare = "https://app.appon.vn/" + val;
      $("#share-app").modal("show");
    },
    showmoreCate: function () {
      $(".list-inline-item").show();
      $(".show-more-cate").hide();
    },
    showCreateName: function () {
      $("#modal-new-app").modal("show");
    },
    selectCategory: function (id, parent_id) {
      var thisVue = this;
      thisVue.resetScroll();
      this.cate_select = id;
      this.parent_cate_select = parent_id;

      if (id && id.length > 1) {
        thisVue.cate_id = id;
        thisVue.list_template_app = [];
        dashboardSV.getAppInCategory($.param({ category: id, skip: thisVue.skip, limit: 20 }), function (data) {
          if (data && data.code === 200) {
            thisVue.list_template_app = data.data;
          } else {
            thisVue.list_template_app = [];
            thisVue.$swal("", "Marketplace openning soon !");
          }
          $("#loading-component").hide();
        });
      } else {
        thisVue.cate_id = "";
        thisVue.getAllPage();
      }
    },
    createApp: function (id) {
      $("#loading-component").show();
      var thisVue = this;
      if (this.newName && this.newName.length > 0) {
        var dataCreate = {
          name: this.newName,
          description: "",
          thumbnail: "",
          type: "app",
          status: "unactive",
          id_template: id
        };
        dashboardSV.createNew(JSON.stringify(dataCreate), function (data) {
          if (data && data.code === 200) {
            // thisVue.$router.push({
            //   name: "Editor",
            //   params: { id: data.data.id }
            // });
            thisVue.$router.push({ path: "/dashboard/", query: {} });
            setTimeout(function () {
              thisVue.$router.push({
                path: "/editor-v3/",
                params: { id: data.data.id }
              });
              thisVue.$router.push({
                name: "Editor3",
                params: { id: data.data.id }
              });
            }, 100);
          } else {
            thisVue.$swal("", "Error !");
            $("#loading-component").hide();
          }
          $("#modal-new-app").modal("hide");
          $("#modal-clone-app").modal("hide");
        });
      } else {
        $("#loading-component").hide();
        this.$swal("", "Please enter Name app!");
      }
    },
    createAppByTemplate: function (id) {
      $("#loading-component").show();
      var thisVue = this;
      if (this.newName && this.newName.length > 0) {
        var dataCreate = {
          name: this.newName,
          description: "",
          thumbnail: "",
          type: "app",
          status: "unactive",
          id_template: id
        };
        dashboardSV.createNewByTemplate(JSON.stringify(dataCreate), function (data) {
          if (data && data.code === 200) {
            // thisVue.$router.push({
            //   name: "Editor",
            //   params: { id: data.data.id }
            // });
            thisVue.$router.push({ path: "/dashboard/", query: {} });
            setTimeout(function () {
              thisVue.$router.push({
                path: "/editor-v3/",
                params: { id: data.data.id }
              });
              thisVue.$router.push({
                name: "Editor3",
                params: { id: data.data.id }
              });
            }, 100);
          } else {
            thisVue.$swal("", "Error !");
            $("#loading-component").hide();
          }
          $("#modal-new-app").modal("hide");
          $("#modal-clone-app").modal("hide");
        });
      } else {
        $("#loading-component").hide();
        this.$swal("", "Please enter Name app!");
      }
    },
    getAllPage: function () {
      $("#loading-component").show();
      var thisVue = this;
      thisVue.list_template_app = [];
      dashboardSV.getAll($.param({ skip: thisVue.skip, limit: 20 }), function (data) {
        if (data.code === 200) {
          thisVue.list_template_app = data.data;
          if (data.data.length <= 0) {
            thisVue.getTemplateApp();
          }
        } else {
          if (data.code === "OAuthException") {
            thisVue.$router.push({ name: "Login" });
          }
        }
        $("#loading-component").hide();
      });
    },
    deleteItemPage: function () {
      this.getAllPage();
    },
    editItemPage: function (id) {
      console.log("id: ", id);
      $("#loading-component").show();
      this.$router.push({ name: "Editor3", params: { id: id } });
    },
    getlistCategory: function () {
      var thisVue = this;
      dashboardSV.getListCategory({}, function (data) {
        if (data && data.code === 200) {
          thisVue.listCate = data.data.filter(cate => cate.type_second === 'app' && cate.id != '5d8c7aa2a91a17680bd15bda');
          console.log('listCate', thisVue.listCate);
          setTimeout(function () {
            $(".list-inline-item:gt(4)").hide();
            $(".show-more-cate").show();
          }, 100);
          thisVue.selectCategory("5d8c7aa2a91a17680bd15bda");
        }
      });
    },
    getInforme: function () {
      var thisVue = this;
      dashboardSV.getInfo({}, function (data) {
        if (data && data.code === 200) {
          localStorage.setItem("user", JSON.stringify(data.data));
          thisVue.user = data.data;
          console.log('user info::', data.data)
        }
      });
    },
    emitCloneApp: function (app) {
      console.log("app clone: ", app);
      this.app_id_clone = app.id;
      this.newName = app.name;
      $("#modal-clone-app").modal("show");
    },
    emitCloneAppTemplate: function (app) {
      console.log("app clone template: ", app);
      let authorization = localStorage.getItem("Authorization")
      let user = localStorage.getItem("user")
      if (!authorization || !user) {
        localStorage.setItem('not-login-clone-app-id', app.id)
        this.$router.push('/register')
        return
      }
      this.app_id_clone = app.id;
      this.newName = app.name;
      $("#modal-clone-template").modal("show");
    },
    menuAnimation: function (i, event) {
      this.menu.select = i;
      setTimeout(function () {
        let height_element = $(event.target).offset().top - 135;
        $('.hightlight__div').first().css({
          'transform': `translate3d(0, ${height_element}px, 0)`,
          'transition': 'transform 0.5s'
        });
      }, 200)
    },
    initLibrary: function () {
      $('.cs-1 .carousel__div').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 4,
        prevArrow: $('.cs-1 .paginator__div .prev__div'),
        nextArrow: $('.cs-1 .paginator__div .next__div')
      })
      $('.cs-2 .carousel__div').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 4,
        prevArrow: $('.cs-2 .paginator__div .prev__div'),
        nextArrow: $('.cs-2 .paginator__div .next__div')
      })
    },
    resetScroll() {
      this.is_scroll = true;
      this.skip = 0;
      this.search = "";
    },
    handleScroll(event) {
      if (event.wheelDelta > 0) return;
      let thisVue = this
      if (!this.is_scroll) return;
      thisVue.skip += 20;
      if (!this.cate_id) {
        dashboardSV.getAll($.param({ skip: thisVue.skip, limit: 20 }), function (data) {
          if (data.code === 200) {
            thisVue.list_template_app = [...thisVue.list_template_app, ...data.data];
            if (data.data.length <= 0) {
              thisVue.is_scroll = false;
            }
          } else {
            if (data.code === "OAuthException") {
              thisVue.$router.push({ name: "Login" });
            }
          }
        });
      } else {
        dashboardSV.getAppInCategory($.param({ category: thisVue.cate_id, skip: thisVue.skip, limit: 20 }), function (data) {
          if (data && data.code === 200) {
            thisVue.list_template_app = [...thisVue.list_template_app, ...data.data];
            if (data.data.length <= 0) {
              thisVue.is_scroll = false;
            }
          }
        });
      }
    },
    checkRef: function () {
      let domain_name = (process.env.NODE_ENV === 'production') ? 'appon.vn' : ''
      let ref = this.$route.query.ref
      console.log('ref::', ref)
      if (!ref) return
      let ref_cookie = CookieService.get_cookie('ref')
      console.log('ref_cookie::', ref_cookie)
      if (ref_cookie) return
      CookieService.set_cookie('ref', ref, 30, domain_name)
    }
  },
  mounted: function () {
    //this.getAllPage();
    this.getlistCategory();
    // this.getInforme();
    if (this.$route.params.id) {
      this.app_id_clone = this.$route.params.id;
      $("#modal-clone-app").modal("show");
    }
    this.initLibrary()
    this.checkRef()
  },
  created() {
    window.addEventListener('wheel', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('wheel', this.handleScroll);
  }
};
