import resfult from "../../../../services/resful.js";
export const sevice = {
  // lấy thông tin 1 invoice
  transferapp: async function (data, next) {
    let res = await resfult.get("/app/page?appid=" + data.appid + "&user_transfer=" + data.accoundid, data);
    next(res);
  },
  getAffiliate: async function (data, next) {
    let res = await resfult.post("/app/affiliate", JSON.stringify(data));
    next(res);
  }
};
